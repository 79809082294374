import React from "react"
import styled from "styled-components"

const BodyBase = styled.div`
  flex: 1 1 auto;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  padding-bottom: 80px;
`

const BodyContainer = styled.div`
  flex: 1 1 auto;
  padding: 8px 16px;
`

const ModalBody = ({ children }) => {
  return (
    <BodyBase>
      <BodyContainer>{children}</BodyContainer>
    </BodyBase>
  )
}

export default ModalBody
