import styled from "styled-components"
export { default as ModalHeader } from "components/Modal/ModalHeader"
export { default as ModalBody } from "components/Modal/ModalBody"
export { default as ModalFooter } from "components/Modal/ModalFooter"

const Modal = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  min-height: 0px;
  max-height: 100vh;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`

export default Modal
