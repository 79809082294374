import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { t } from "src/i18n/config"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Img = styled.i`
  font-size: 64px;
  color: ${props => props.theme.colors.error};
  line-height: initial;
`

const Title = styled.div`
  margin-top: 8px;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.07px;
  text-align: center;
`
const Description = styled.div`
  margin: 16px 48px 0 48px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: center;
`

const Phone = styled.a`
  margin: 16px 48px 0 48px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
`

FailedPage.defaultProps = {
  contact: "",
}

function FailedPage({ contact, message }) {
  return (
    <React.Fragment>
      <Container>
        <Img className="ri-close-circle-line"></Img>
        <Title>{t("cancelFailed")}</Title>
        {message && <Description>{message}</Description>}
        <Description>{t("contactToReschedule")}</Description>
        <Phone href={`tel:${contact}`}>{contact}</Phone>
      </Container>
    </React.Fragment>
  )
}

FailedPage.propTypes = {
  message: PropTypes.string,
  contact: PropTypes.string,
}

export default FailedPage
